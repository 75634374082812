// mkt_basket.js v1.0.3 date 18.09.23
(function (global, factory) {
   typeof exports === 'object' && typeof module !== 'undefined' ? module.exports = factory() :
   typeof define === 'function' && define.amd ? define(factory) :
   (global = typeof globalThis !== 'undefined' ? globalThis : global || self, global.MKTbasket = factory());
}(this, (function () { 'use strict';

   var sitename = (location.hostname || 'kommek.ru');
   var sitepath = (location.protocol || 'https:') + '//' + sitename;
   var measure = '&nbsp;шт.';
   var preSet={};
   
   var changeBasketNumb = function(id_good,number,aCallback) {
      new kmkCMS.loadJSON({
         method: 'POST',
         script: sitepath + '/cgi-bin/api/mkt_goods_basket.cgi',
         json: {[id_good]:number},
         success: changeNumberOnPage,
      });
      function changeNumberOnPage(json){
         preSet.lable = json.number || 0;
         preSet.number = 0;
         if(preSet.lable){
             for(let i = 0; i < json.basket.length; i++) {
                 let obj = json.basket[i];
                 if(obj.id_good==id_good){
                     preSet.id_good = obj.id_good;
                     preSet.number = obj.number||1;
                     break;
                 }
             }
         }
         if(preSet.number === 0 && Object.entries(preSet).length !== 0){
             preSet.button.style.display = null;
             preSet.countHTML.remove();
         }
         
         let elements = document.querySelectorAll('.header__user-basket-number');
         for (let i = 0; i < elements.length; i++) {
            let elem = elements[i].querySelector('span.wishlist-item-count');
            if(!elem){
               let obj = document.createElement("span");
               obj.className = "wishlist-item-count pos-absolute";
               elements[i].appendChild(obj);
               elem = obj;
            }
            elem.innerHTML = (preSet.lable === 0)? 0 : preSet.lable;
         }

         //let elements = document.querySelectorAll('.wishlist-item-count');
         //for (let i = 0; i < elements.length; i++) {
         //  elements[i].innerHTML = (preSet.lable === 0)? 0 : preSet.lable;
         //}
         if (aCallback && typeof aCallback === 'function') {
             aCallback(id_good,preSet.number);
         }
      }
   };

   var showToast = function(options) {
       const defaults = {
           ID: undefined,
           message: undefined,
           wait: 2000,
           class: undefined,
       };
       let settings = kmkCMSutil.extend( defaults, options || {} );
       var objCreate = function() { 
           var obj = document.createElement("div");
           obj.id = settings.ID;
           obj.className = settings.class;
           obj.style.display = "none";
           kmkCMSutil.setElementOpacity(obj,0);
           obj.innerHTML = settings.message;
           document.body.appendChild(obj);
           return obj;
       };
       var obj;
       if (typeof(settings.ID) == 'undefined'){
           console.warn("Для функции showToast не задан обязательный параметр ID");
           return;
       }else{
           if (typeof(settings.ID)=='object'){
               obj = settings.ID;
           }else{
               obj = document.getElementById(settings.ID);
           }
       }
       if(!obj) obj = objCreate();
       obj.style.display = "block";
       kmkCMSutil.setElementOpacity(obj,100);
       setTimeout(function() {
           obj.style.display = "none";
           kmkCMSutil.setElementOpacity(obj,0);
       }, settings.wait);
   };
   
   var showLable = function(options) {
       const defaults = {
           lable: undefined,
           number: undefined,
           message: undefined,
       };
       let settings = kmkCMSutil.extend( defaults, options || {} );
       
       let elem = document.querySelector('a[name="good_show_'+settings.lable+'"]');
       let selector;
       if (elem) {
         if (sitename.indexOf('kommek.ru')>-1){
            elem = elem.nextElementSibling;
            selector = '.product__label.product__label--inbasket';
         }else{
            elem = elem.parentNode;
            selector = '.tps_good_show_tvrin';
         }
         let obj = elem.querySelector(selector);
         if(obj) {
             obj.innerHTML = (settings.number === 0)? '' : settings.message + settings.number + measure;
         }
       }
   };

   var showCount = function(number) {
       if(preSet.countHTML && number){
           let obj = preSet.countHTML.querySelector('span');
           obj.innerHTML = number + measure;
       }
   };

   var product__basket_show = function(id_good,number) {
       //showToast({
       //    ID:'toast-basket',
       //    class: 'toast-basket alert alert-success',
       //    message:'товар добавлен',
       //});
       showCount(number);
       showLable({
           lable: id_good,
           number: number,
           message:'В корзине ',
       });
   };

   var product__basket_add = function(id_good,number) {
       return (e) => {
           e.preventDefault();
           changeBasketNumb(id_good,number,product__basket_show);
           //return false;
       };
   };
   
   
   var showBasketCount = function(id_good){
       return (e) => {
           e.preventDefault();
           let objButton = e.currentTarget;
           let obj = objButton.parentElement;
           if(Object.entries(preSet).length !== 0){
               preSet.button.style.display = null;
               preSet.countHTML.remove();
           }
           
           let showHTMLCounter = function(id_good,number) {
               preSet.countHTML = createHTMLCounter(id_good,number);
               obj.appendChild(preSet.countHTML);
               product__basket_show(id_good,number);
           };
           var createHTMLCounter = function(id_good,number) {
               let obj = document.createElement("div");
               obj.className = "count-basket";
               
               let button = document.createElement("button");
               button.className = "btn count-basket__btn minus";
               button.type = "button";
               button.onclick = product__basket_add(id_good,-1);
               button.innerHTML = '<i class="fak fa-minus"></i>';
               obj.appendChild(button);
               
               let count = document.createElement("span");
               count.innerHTML = number + measure;
               obj.appendChild(count);
               
               button = document.createElement("button");
               button.className = "btn count-basket__btn plus";
               button.type = "button";
               button.onclick = product__basket_add(id_good,1);
               button.innerHTML = '<i class="fak fa-plus"></i>';
               obj.appendChild(button);
               
               return obj;
           };
           
           changeBasketNumb(id_good,1,showHTMLCounter);
           
           preSet.button = objButton;
           preSet.button.style.display = "none";

       };  
   };
    
   const MKTbasket = function() {
      let elements = document.querySelectorAll('.product__basket > a');
      for (let i = 0; i < elements.length; i++) {
         let elem = elements[i];
         let id_good = elem.getAttribute('data-good');
         if(id_good) elem.onclick = showBasketCount(id_good);
      }
   };

   return MKTbasket;

})));